import {
  ColumnFilterType,
  NumericFilterModeName,
  TextFilterModeName,
} from '@shared/enums/filters/table-filters';
import { DataTableConfig, TableActions, TableColumnDisplay } from '../shared/interfaces/table';
import { format } from 'date-fns';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PrimeIcons } from 'primeng/api';
import { InvoiceDetailResponse } from '@shared/interfaces/invoice.interface';
import { DateFilterComponent } from '@shared/components/table/custom-filters/date-filter/date-filter.component';

export const InvoiceTableConfig: DataTableConfig<InvoiceDetailResponse> = {
  endPoint: 'assetmanagement/projects/{project_id}/invoices/',
  showCheckboxes: true,
  showActions: true,
  additionalParams: [
    {
      key: 'is_invoice',
      value: true,
    },
  ],
  actionItems: [
    {
      value: TableActions.openEditMode,
      label: 'Edit',
      icon: PrimeIcons.PENCIL,
      disabled: false,
      permission: AllGroupPermissions.CHANGE_INVOICE,
    },
    {
      value: TableActions.exportToPDF,
      label: 'Export',
      icon: PrimeIcons.FILE_PDF,
      disabled: false,
      permission: AllGroupPermissions.DOWNLOAD_INVOICE,
    },
    {
      value: TableActions.deleteItem,
      label: 'Delete',
      icon: PrimeIcons.TRASH,
      disabled: false,
      permission: AllGroupPermissions.DELETE_INVOICE,
    },
  ],
  sortBy: 'name',
  fixedWidth: true,
  fixedMinWidth: '1300px',
  formatData: (data: InvoiceDetailResponse[]) => {
    return data.map((item) => {
      return {
        ...item,
        due_date: item.due_date && format(item.due_date, 'MM-dd-yyyy'),
        settlement_period_start_date:
          item.settlement_period_start_date &&
          format(item.settlement_period_start_date, 'MM-dd-yyyy'),
        settlement_period_end_date:
          item.settlement_period_end_date && format(item.settlement_period_end_date, 'MM-dd-yyyy'),
        energy_mwh: item.energy_mwh ? item.energy_mwh.toFixed(2) : null,
      } as unknown as InvoiceDetailResponse;
    });
  },
  columns: [
    {
      title: 'Invoice ID',
      name: 'code',
      displayType: TableColumnDisplay.value,
      width: '8%',
      hasColumnFilters: false,
      sortable: false,
      sortConfig: {
        sortKey: 'code',
      },
    },
    {
      title: 'Offtaker',
      name: 'offtaker_name',
      action: TableActions.viewProjectDetails,
      displayType: TableColumnDisplay.link,
      width: '8%',
      sortable: false,
      hasColumnFilters: false,
    },
    {
      title: 'Type',
      name: 'invoice_type',
      displayType: TableColumnDisplay.invoiceType,
      width: '9%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'invoice_type',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Invoice Type',
        fieldName: 'invoice_type',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    {
      title: 'Status',
      name: 'invoice_status',
      displayType: TableColumnDisplay.invoiceStatus,
      width: '10%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'invoice_status',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Status',
        fieldName: 'invoice_status',
        type: ColumnFilterType.TEXT,
        showApplyButton: true,
      },
    },
    {
      title: 'Due Date',
      name: 'due_date',
      displayType: TableColumnDisplay.value,
      width: '10%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'due_date',
      },
      filterConfig: {
        options: Object.values(NumericFilterModeName),
        selectedFilter: NumericFilterModeName.EQUALS,
        placeholder: 'mm-dd-yyyy',
        fieldName: 'due_date',
        type: ColumnFilterType.CUSTOM,
        component: DateFilterComponent as never,
      },
    },
    {
      title: 'Energy',
      name: 'energy_mwh',
      action: TableActions.viewProjectDetails,
      displayType: TableColumnDisplay.capacity,
      width: '9%',
      sortable: true,
      hasColumnFilters: false,
      sortConfig: {
        sortKey: 'energy_mwh',
      },
    },
    {
      title: 'Final Inv Amount',
      name: 'final_invoice_amount',
      action: TableActions.viewProjectDetails,
      displayType: TableColumnDisplay.amount,
      width: '12%',
      sortable: true,
      hasColumnFilters: false,
      sortConfig: {
        sortKey: 'final_invoice_amount',
      },
    },
    {
      title: 'Settlement Start Date',
      name: 'settlement_period_start_date',
      displayType: TableColumnDisplay.value,
      width: '15%',
      hasColumnFilters: false,
      sortable: true,
      sortConfig: {
        sortKey: 'settlement_period_start_date',
      },
      filterConfig: {
        options: Object.values(NumericFilterModeName),
        selectedFilter: NumericFilterModeName.EQUALS,
        placeholder: 'mm-dd-yyyy',
        fieldName: 'settlement_period_start_date',
        type: ColumnFilterType.CUSTOM,
        component: DateFilterComponent as never,
      },
    },
    {
      title: 'Settlement End Date',
      name: 'settlement_period_end_date',
      displayType: TableColumnDisplay.value,
      width: '15%',
      hasColumnFilters: false,
      sortable: true,
      sortConfig: {
        sortKey: 'settlement_period_end_date',
      },
      filterConfig: {
        options: Object.values(NumericFilterModeName),
        selectedFilter: NumericFilterModeName.EQUALS,
        placeholder: 'mm-dd-yyyy',
        fieldName: 'settlement_period_end_date',
        type: ColumnFilterType.CUSTOM,
        component: DateFilterComponent as never,
      },
    },
  ],
};
